import useQuery from '../../shared/useQuery';

export type Agent = {
  _id: string;
  firstName: string;
  lastName: string;
};

export default function useAgent (agentId?: string) {
  return useQuery<Agent>(agentId ? `/notebook/agents/${agentId}` : null);
}
