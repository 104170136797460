import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import SystemLayout from '../../Layouts/SystemLayout';
import { AGENT_PULL_STRATEGY, HOME_CLOSE_SOURCES, Partner, State, usePartner } from '../../../api/queries/partners/usePartner';
import ShowPartnerLayout from '../../Layouts/ShowPartnerLayout';
import { Button, Card, Col, Row, Table } from 'react-bootstrap';
import LabelValueGrouping from '../../../components/LabelValueGrouping';
import { formatDate, formatDollars, useExpandableState } from '../../../utils';
import EditActiveStatesModal from '../../../components/Modals/EditActiveStatesModal';
import { mutate } from 'swr';
import Drawer from '../../../components/Drawer';
import EditPartnerInfo from '../../../components/System/Partners/EditPartnerInfo';
import EditPartnerLogos from '../../../components/System/Partners/EditPartnerLogos';
import EditPartnerUrls from '../../../components/System/Partners/EditPartnerUrls';
import EditPartnerMarqetaFields from '../../../components/System/Partners/EditPartnerMarqetaFields';
import EditPartnerContactInfo from '../../../components/System/Partners/EditPartnerContactInfo';
import EditTrelloLists from '../../../components/System/Partners/EditTrelloLists';
import EditDisbursementFields from '../../../components/System/Partners/EditDisbursementFields';
import EditPartnerFavicons from '../../../components/System/Partners/EditPartnerFavicons';
import EditPartnerAgentSettings from '../../../components/System/Partners/EditPartnerAgentSettings';
import EditPartnerAgentPricings from '../../../components/System/Partners/EditPartnerAgentPricings';
import DeletePartnerModal from '../../../components/Modals/DeletePartnerModal';
import EditPartnerHomeCloseSettings from '../../../components/System/Partners/EditPartnerHomeCloseSettings';
import EditPartnerReportingSettings from '../../../components/System/Partners/EditPartnerReportingSettings';
import { startCase } from 'lodash';
import EditPartnerHubspotSettings from '../../../components/System/Partners/EditPartnerHubspotSettings';
import EditSystemSettingsLink from '../../../components/EditSystemSettingsLink';

type EditDrawerType = 'info' | 'logos' | 'urls' | 'marqeta' | 'contact' | 'trello' | 'disbursements' | 'agent_settings' | 'favicons' | 'agent_pricing' | 'home_close_settings' | 'reporting_settings' | 'hubspot' | null;

export default function PartnerShowInfoPage () {
  const { partnerId } = useParams<{ partnerId: string }>();
  const [editDrawerType, setEditDrawerType] = useState<EditDrawerType>(null);
  const deletePartnerModal = useExpandableState();
  const baseSheetsUrl = 'https://docs.google.com/spreadsheets/d/';

  const { data: partner } = usePartner(partnerId);
  if (!partner) {
    return null;
  }
  return (<>
    <SystemLayout selectedTab='partners' heading={partner.short_name}>
      <ShowPartnerLayout partner={partner} selectedTab='info'>
        <section className='mb-4 mt-2 d-flex flex-column'>
          { !partner.active &&
            <Button className='ml-auto h-100 mt-n5 mb-2' variant='outline-dark' onClick={ () => deletePartnerModal.showExpand() }>Delete partner</Button>
          }
          <Row>
            <Col>
              <Card>
                <Card.Header>
                  Info
                  <EditSystemSettingsLink className="float-right" onClick={() => setEditDrawerType('info')}/>
                </Card.Header>

                <Card.Body>
                  <Row>
                    <Col>
                      <LabelValueGrouping
                        data={{
                          'Name': partner.name,
                          'Brand Name': partner.brand_name,
                          'Legal Name': partner.legal_name,
                          'Short Name': partner.short_name,
                          'Slug': partner.slug,
                          'Program': partner.program.name,
                          'Active': partner.active ? 'Yes' : 'No',
                          'Waitlist': partner.waitlistEnabled ? 'ON' : 'OFF',
                          'Disallowed Property Entities': partner.disallowedPropertyEntities,
                          'Landing Page': partner.landingPage,
                          'CSS Theme': startCase(partner.theme),
                          'Funnel Start View': partner.funnel_start_view,
                          'Cash Back Accrual': partner.cashBackEnabled ? 'ON' : 'OFF',
                          'Show Funnel Intro': partner.showFunnelIntro ? 'ON' : 'OFF',
                          'Forced Manual Review': partner.forcedManualReview ? 'ON' : 'OFF',
                          'Launch Date': formatDate(partner.launchDate) || '--',
                          'Contract Renewal Date': formatDate(partner.contractRenewalDate) || '--',
                          'Annual Increase Percentage': `${partner.annualIncreasePercentage}%`,
                          'Payments Page': partner.paymentsPageEnabled ? 'ON' : 'OFF',
                        }}
                        condensed={ true }
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col>
              <Card className='mt-4'>
                <Card.Header>
                  Agent Settings
                  <EditSystemSettingsLink className="float-right" onClick={() => setEditDrawerType('agent_settings')}/>
                </Card.Header>

                <Card.Body>
                  <Row>
                    <Col>
                      <LabelValueGrouping
                        data={{
                          'Supports Agents': partner.agentSupport ? 'ON' : 'OFF',
                          ...(partner.agentSupport && { 'Storage Strategy': partner.agentStorageStrategy }),
                          ...(partner.agentSupport && { 'Agent Pull Strategy': partner.agentPullStrategy ?? 'None' }),
                          ...(partner.agentSupport && partner.agentPullStrategy === AGENT_PULL_STRATEGY.GOOGLE_SHEET &&
                            { 'Remote Agent Google Sheet': partner.remoteAgentGoogleSheetId ?
                              <a
                                target='_blank'
                                rel="noreferrer"
                                href={`${baseSheetsUrl}${partner.remoteAgentGoogleSheetId}`}
                              >
                                {`${baseSheetsUrl}${partner.remoteAgentGoogleSheetId}`}
                              </a>
                              : '--'
                            }
                           ),
                          ...(partner.agentSupport && partner.agentPullStrategy === AGENT_PULL_STRATEGY.ANYWHERE_API && {
                            'Remote Agent Anywhere Brand Code': partner.agentRosterAnywhereBrandCode ?? '--'
                          }),
                          ...(partner.agentSupport && { 'Agent Pricing Payment Type': partner.agentPricingPaymentType }),
                          ...(partner.agentSupport && { 'Promo Start Date': formatDate(partner.promoStartDate) }),
                          ...(partner.agentSupport && { 'Promo End Date': formatDate(partner.promoEndDate) }),
                          ...(partner.agentSupport && { 'Email Domain': partner.emailDomain }),
                          ...(partner.agentSupport && { 'Agents URL': <a target='_blank' rel="noreferrer" href={partner.agentsUrl}>{partner.agentsUrl}</a> }),
                          ...(partner.agentSupport && { 'Personalized Landing Pages': partner.agentLandingPageEnabled ? 'ON' : 'OFF' }),
                        }}
                        condensed={ true }
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col>
              <Card className='mt-4'>
                <Card.Header>
                  Home Close Settings
                  <EditSystemSettingsLink className="float-right" onClick={() => setEditDrawerType('home_close_settings')}/>
                </Card.Header>

                <Card.Body>
                  <Row>
                    <Col>
                      <LabelValueGrouping
                        data={{
                          'Home Close Source': partner.homeCloseSource ?? '--',
                          ...(partner.homeCloseSource === HOME_CLOSE_SOURCES.GOOGLE_SHEET && { 'Home Close Google Sheet':
                            <a
                              target='_blank'
                              rel="noreferrer"
                              href={`${baseSheetsUrl}${partner.homeCloseGoogleSheetId}`}
                            >
                              {`${baseSheetsUrl}${partner.homeCloseGoogleSheetId}`}
                            </a>
                          }),
                          ...(partner.homeCloseSource === HOME_CLOSE_SOURCES.CSV_SFTP && { 'Home Close SFTP CSV File Name': partner.homeCloseSftpCsvFileName }),
                          ...(partner.homeCloseSource === HOME_CLOSE_SOURCES.ANYWHERE_API && { 'Home Close Anywhere Brand Code': partner.homeCloseAnywhereBrandCode }),
                        }}
                        condensed={ true }
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col>
              <Card className='mt-4'>
                <Card.Header>
                  Reporting Settings
                  <EditSystemSettingsLink className="float-right" onClick={() => setEditDrawerType('reporting_settings')}/>
                </Card.Header>

                <Card.Body>
                  <Row>
                    <Col>
                      <LabelValueGrouping
                        data={{
                          'Weekly Origination Report': partner.weeklyOriginationReportEnabled ? 'ON' : 'OFF',
                          'Weekly Origination Report Recipients': partner.weeklyOriginationReportRecipients.join(', ') || '--',
                        }}
                        condensed={ true }
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          { partner.agentSupport &&
            <Row>
              <Col>
                <Card className='mt-4'>
                  <Card.Header>
                    Agent Pricing
                    <EditSystemSettingsLink className="float-right" onClick={() => setEditDrawerType('agent_pricing')}/>
                  </Card.Header>

                  <Card.Body>
                    <AgentPricing partner={partner} />
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          }

          <Row>
            <Col>
              <ActiveStates states={partner.states} partnerId={partner._id}/>
            </Col>
          </Row>

          <Row>
            <Col>
              <Card className='mt-4'>
                <Card.Header>
                  URLs
                  <EditSystemSettingsLink className="float-right" onClick={() => setEditDrawerType('urls')}/>
                </Card.Header>

                <Card.Body>
                  <Row>
                    <Col>
                      <LabelValueGrouping
                        data={{
                          'URL': <a target='_blank' rel="noreferrer" href={partner.url}>{partner.url}</a>,
                          'Help URL': <a target='_blank' rel="noreferrer" href={partner.helpUrl}>{partner.helpUrl}</a>,
                          'Learn More URL': <a target='_blank' rel="noreferrer" href={partner.learnMoreUrl}>{partner.learnMoreUrl}</a>,
                        }}
                        condensed={ true }
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col>
              <Card className='mt-4'>
                <Card.Header>
                  Contact info
                  <EditSystemSettingsLink className="float-right" onClick={() => setEditDrawerType('contact')}/>
                </Card.Header>

                <Card.Body>
                  <Row>
                    <Col>
                      <LabelValueGrouping
                        data={{
                          'Support Email': partner.supportEmail,
                          'Support Phone Number': partner.supportPhoneNumber,
                          'SLA Response Days': partner.slaResponseDays,
                        }}
                        condensed={ true }
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col>
              <Card className='mt-4'>
                <Card.Header>
                  Funnel & Portal Assets
                  <EditSystemSettingsLink className="float-right" onClick={() => setEditDrawerType('logos')}/>
                </Card.Header>

                <Card.Body>
                  <Row>
                    <Col>
                      <LabelValueGrouping
                        data={{
                          'Email Header Logo': <a target='_blank' rel="noreferrer" href={partner.emailHeaderLogoUrl}>{partner.emailHeaderLogoUrl}</a>,
                          'Funnel Apply Screen Background': <a target='_blank' rel="noreferrer" href={partner.funnelApplyScreenLogoUrl}>{partner.funnelApplyScreenLogoUrl}</a>,
                          'Funnel Intro Screen Background Step 1': partner.funnelIntroBackground1Url ? <a target='_blank' rel="noreferrer" href={partner.funnelIntroBackground1Url}>{partner.funnelIntroBackground1Url}</a> : '--',
                          'Funnel Intro Screen Background Step 2': partner.funnelIntroBackground2Url ? <a target='_blank' rel="noreferrer" href={partner.funnelIntroBackground2Url}>{partner.funnelIntroBackground2Url}</a> : '--',
                          'Funnel Intro Screen Background Step 3': partner.funnelIntroBackground3Url ? <a target='_blank' rel="noreferrer" href={partner.funnelIntroBackground3Url}>{partner.funnelIntroBackground3Url}</a> : '--',
                          'Funnel Sidebar Logo & Modal Logo': <a target='_blank' rel="noreferrer" href={partner.funnelSidebarLogoUrl}>{partner.funnelSidebarLogoUrl}</a>,
                          'Portal Navbar Logo': <a target='_blank' rel="noreferrer" href={partner.portalNavbarLogoUrl}>{partner.portalNavbarLogoUrl}</a>,
                          'Physical Card Image': <a target='_blank' rel="noreferrer" href={partner.marqetaCardLogoUrl}>{partner.marqetaCardLogoUrl}</a>,
                        }}
                        condensed={ true }
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col>
              <Card className='mt-4'>
                <Card.Header>
                  Meta Assets
                  <EditSystemSettingsLink className="float-right" onClick={() => setEditDrawerType('favicons')}/>
                </Card.Header>

                <Card.Body>
                  <Row>
                    <Col>
                      <LabelValueGrouping
                        data={{
                          'Apple Touch Icon': <a target='_blank' rel="noreferrer" href={partner.appleTouchIconUrl}>{partner.appleTouchIconUrl}</a>,
                          'Favicon 16x16': <a target='_blank' rel="noreferrer" href={partner.favicon16Url}>{partner.favicon16Url}</a>,
                          'Favicon 32x32': <a target='_blank' rel="noreferrer" href={partner.favicon32Url}>{partner.favicon32Url}</a>,
                          'Favicon': <a target='_blank' rel="noreferrer" href={partner.faviconUrl}>{partner.faviconUrl}</a>,
                          'Android 192x192 Icon': <a target='_blank' rel="noreferrer" href={partner.android192IconUrl}>{partner.android192IconUrl}</a>,
                          'Android 512x512 Icon': <a target='_blank' rel="noreferrer" href={partner.android512IconUrl}>{partner.android512IconUrl}</a>,
                          'Social Image': <a target='_blank' rel="noreferrer" href={partner.socialImageUrl}>{partner.socialImageUrl}</a>,
                        }}
                        condensed={ true }
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col>
              <Card className='mt-4'>
                <Card.Header>
                  Marqeta
                  <EditSystemSettingsLink className="float-right" onClick={() => setEditDrawerType('marqeta')}/>
                </Card.Header>

                <Card.Body>
                  <Row>
                    <Col>
                      <LabelValueGrouping
                        data={{
                          'Marqeta Program': partner.marqetaProgramSlug,
                          'Supports Physical Cards': partner.supportsPhysicalCard ? 'ON' : 'OFF',
                          'Marqeta Physical Card Product Token': partner.marqetaPhysicalCardProductToken,
                          'Supports Virtual Cards': partner.supportsVirtualCard ? 'ON' : 'OFF',
                          'Marqeta Virtual Card Product Token': partner.marqetaVirtualCardProductToken,
                          'Marqeta Application Id': partner.marqetaApplicationId,
                          'Marqeta Carrier Card Personalization Enabled': partner.marqetaCarrierCardPersonalizationEnabled ? 'ON' : 'OFF',
                          'Marqeta Card Logo Name': partner.marqetaCardLogoName,
                        }}
                        condensed={ true }
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col>
              <Card className='mt-4'>
                <Card.Header>
                  The Number
                </Card.Header>

                <Card.Body>
                  <Row>
                    <Col>
                      <LabelValueGrouping
                        data={{
                          'Org': partner.theNumberOrg,
                          'Portfolio': partner.theNumberPortfolio,
                          'Re-pull Org': partner.theNumberRepullOrg,
                        }}
                        condensed={ true }
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col>
              <Card className='mt-4'>
                <Card.Header>
                  Disbursements
                  <EditSystemSettingsLink className="float-right" onClick={() => setEditDrawerType('disbursements')}/>
                </Card.Header>

                <Card.Body>
                  <Row>
                    <Col>
                      <LabelValueGrouping
                        data={{
                          'Supported Payment Types': partner.supportedPayTypes?.length ? partner.supportedPayTypes : '--',
                          'Supported Vendor Types': partner.supportedVendorTypes?.length ? partner.supportedVendorTypes : '--',
                          'Max Quick Deposit': formatDollars(partner.maxQuickDepositCents, { cents: true }),
                          'Check Mailing Vendor': startCase(partner.checkMailingVendor),
                          'Express Checks Enabled': partner.expressChecksEnabled ? 'ON' : 'OFF',
                          'Internal Account': `Account ending in ${partner.disbursementAccount?.lastFour}`,
                          'Lob Account': partner.disbursementAccount?.lobAccount,
                          'Postgrid Account': partner.disbursementAccount?.postgridAccount,
                        }}
                        condensed={ true }
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col>
              <Card className='mt-4'>
                <Card.Header>
                  Trello Lists Ids
                  <EditSystemSettingsLink className="float-right" onClick={() => setEditDrawerType('trello')}/>
                </Card.Header>

                <Card.Body>
                  <Row>
                    <Col>
                      <LabelValueGrouping
                        data={{
                          'Under Review Loan App': partner.trelloUnderReviewLoanAppListId || '--',
                          'Denied Loan App': partner.trelloDeniedLoanAppListId || '--',
                          'Ineligible Loan App': partner.trelloIneligibleLoanAppListId || '--',
                          'Acceleration Not Confirmed': partner.trelloAccelerationNotConfirmedListId || '--',
                        }}
                        condensed={ true }
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col>
              <Card className='mt-4'>
                <Card.Header>
                  Hubspot
                  <EditSystemSettingsLink className="float-right" onClick={() => setEditDrawerType('hubspot')}/>
                </Card.Header>

                <Card.Body>
                  <Row>
                    <Col>
                      <LabelValueGrouping
                        data={{
                          'Allow syncing to Hubspot': partner.hubspotAllowSync  ? 'ON' : 'OFF',
                          'Hubspot company domain': partner.hubspotCompanyDomain,
                          'Hubspot company ID': partner.hubspotId,
                        }}
                        condensed={ true }
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>

        </section>
        {
          editDrawerType && (
            <>
              {
                editDrawerType === 'info' && (
                  <Drawer
                    title   = "Edit"
                    onClose = { () => setEditDrawerType(null) }
                    size    = "md"
                  >
                    <EditPartnerInfo
                      partner={ partner }
                      onSubmit={ () => mutate(`/notebook/partners/${partnerId}`) }
                      onHide={ () => setEditDrawerType(null) }
                    />
                  </Drawer>
                )
              }
              {
                editDrawerType === 'agent_settings' && (
                  <Drawer
                    title   = "Edit"
                    onClose = { () => setEditDrawerType(null) }
                    size    = "md"
                  >
                    <EditPartnerAgentSettings
                      partner={ partner }
                      onSubmit={ () => mutate(`/notebook/partners/${partnerId}`) }
                      onHide={ () => setEditDrawerType(null) }
                    />
                  </Drawer>
                )
              }
              {
                editDrawerType === 'home_close_settings' && (
                  <Drawer
                    title   = "Edit"
                    onClose = { () => setEditDrawerType(null) }
                    size    = "md"
                  >
                    <EditPartnerHomeCloseSettings
                      partner={ partner }
                      onSubmit={ () => mutate(`/notebook/partners/${partnerId}`) }
                      onHide={ () => setEditDrawerType(null) }
                    />
                  </Drawer>
                )
              }
              {
                editDrawerType === 'reporting_settings' && (
                  <Drawer
                    title   = "Edit"
                    onClose = { () => setEditDrawerType(null) }
                    size    = "md"
                  >
                    <EditPartnerReportingSettings
                      partner={ partner }
                      onSubmit={ () => mutate(`/notebook/partners/${partnerId}`) }
                      onHide={ () => setEditDrawerType(null) }
                    />
                  </Drawer>
                )
              }
              {
                editDrawerType === 'agent_pricing' && (
                  <Drawer
                    title   = "Edit"
                    onClose = { () => setEditDrawerType(null) }
                    size    = "xl"
                  >
                    <EditPartnerAgentPricings
                      partner={ partner }
                      onSubmit={ () => mutate(`/notebook/partners/${partnerId}`) }
                      onHide={ () => setEditDrawerType(null) }
                    />
                  </Drawer>
                )
              }
              {
                editDrawerType === 'logos' && (
                  <Drawer
                    title   = "Edit"
                    onClose = { () => setEditDrawerType(null) }
                    size    = "xl"
                  >
                    <EditPartnerLogos
                      partner={ partner }
                      onSubmit={ () => mutate(`/notebook/partners/${partnerId}`) }
                      onHide={ () => setEditDrawerType(null) }
                    />
                  </Drawer>
                )
              }
              {
                editDrawerType === 'favicons' && (
                  <Drawer
                    title   = "Edit"
                    onClose = { () => setEditDrawerType(null) }
                    size    = "xl"
                  >
                    <EditPartnerFavicons
                      partner={ partner }
                      onSubmit={ () => mutate(`/notebook/partners/${partnerId}`) }
                      onHide={ () => setEditDrawerType(null) }
                    />
                  </Drawer>
                )
              }
              {
                editDrawerType === 'urls' && (
                  <Drawer
                    title   = "Edit"
                    onClose = { () => setEditDrawerType(null) }
                    size    = "md"
                  >
                    <EditPartnerUrls
                      partner={ partner }
                      onSubmit={ () => mutate(`/notebook/partners/${partnerId}`) }
                      onHide={ () => setEditDrawerType(null) }
                    />
                  </Drawer>
                )
              }
              {
                editDrawerType === 'marqeta' && (
                  <Drawer
                    title   = "Edit"
                    onClose = { () => setEditDrawerType(null) }
                    size    = "md"
                  >
                    <EditPartnerMarqetaFields
                      partner={ partner }
                      onSubmit={ () => mutate(`/notebook/partners/${partnerId}`) }
                      onHide={ () => setEditDrawerType(null) }
                    />
                  </Drawer>
                )
              }
              {
                editDrawerType === 'contact' && (
                  <Drawer
                    title   = "Edit"
                    onClose = { () => setEditDrawerType(null) }
                    size    = "md"
                  >
                    <EditPartnerContactInfo
                      partner={ partner }
                      onSubmit={ () => mutate(`/notebook/partners/${partnerId}`) }
                      onHide={ () => setEditDrawerType(null) }
                    />
                  </Drawer>
                )
              }
              {
                editDrawerType === 'trello' && (
                  <Drawer
                    title   = "Edit"
                    onClose = { () => setEditDrawerType(null) }
                    size    = "md"
                  >
                    <EditTrelloLists
                      partner={ partner }
                      onSubmit={ () => mutate(`/notebook/partners/${partnerId}`) }
                      onHide={ () => setEditDrawerType(null) }
                    />
                  </Drawer>
                )
              }
              {
                editDrawerType === 'hubspot' && (
                  <Drawer
                    title   = "Edit"
                    onClose = { () => setEditDrawerType(null) }
                    size    = "md"
                  >
                    <EditPartnerHubspotSettings
                      partner={ partner }
                      onSubmit={ () => mutate(`/notebook/partners/${partnerId}`) }
                      onHide={ () => setEditDrawerType(null) }
                    />
                  </Drawer>
                )
              }
              {
                editDrawerType === 'disbursements' && (
                  <Drawer
                    title   = "Edit"
                    onClose = { () => setEditDrawerType(null) }
                    size    = "md"
                  >
                    <EditDisbursementFields
                      partner={ partner }
                      onSubmit={ () => mutate(`/notebook/partners/${partnerId}`) }
                      onHide={ () => setEditDrawerType(null) }
                    />
                  </Drawer>
                )
              }
            </>
          )
        }
      </ShowPartnerLayout>
    </SystemLayout>

    <DeletePartnerModal
      expandable={ deletePartnerModal }
      partner={ partner }
    />
  </>);
}

function AgentPricing ({ partner }: { partner: Partner }) {
  if (partner.agentPricing.length === 0) {
    return <div>No agent pricings</div>;
  }

  return (
    <div>
      <Table>
        <thead>
        <tr>
          <th className='border-0 pl-0'>List Price</th>
          <th className='border-0 pl-0'>Rate</th>
        </tr>
        </thead>

        <tbody>
        {
          partner.agentPricing.map((pricing, index) => {
            const minListPrice = formatDollars(pricing.minListPriceCentsBucket, { cents: true, precision: 0 });
            const maxListPrice = index === partner.agentPricing.length - 1 ? '∞' : formatDollars(partner.agentPricing[index +  1].minListPriceCentsBucket - 100, { cents: true, precision: 0 });
            return <tr key={pricing._id}>
              <td className='pl-0'>{minListPrice} - {maxListPrice}</td>
              <td className='pl-0'>{formatDollars(pricing.rateCents, { cents: true, precision: 0 })}</td>
            </tr>;
          })
        }
        </tbody>
      </Table>
    </div>
  );
}

function ActiveStates ({ states, partnerId }: { states: Array<State>, partnerId: string }) {
  const editActiveStates = useExpandableState();
  const primaryStates = states.filter(state => state.isPrimary);

  return <Card className='mt-4' data-qa='states'>
    <Card.Header>
      States
      <EditSystemSettingsLink className="float-right" onClick={editActiveStates.showExpand}/>
    </Card.Header>

    <Card.Body>
      <div>Active States</div>
      <StateCodes states={states}/>

      <div className='mt-3'>Primary State{primaryStates.length > 1 ? 's' : ''}</div>
      <StateCodes states={primaryStates}/>
    </Card.Body>

    <EditActiveStatesModal
      expand={editActiveStates}
      states={states}
      endpoint={`/notebook/partners/${partnerId}/states`}
      onSubmit={() => mutate(`/notebook/partners/${partnerId}`)}
      hasPrimary
    />
  </Card>;
}

function StateCodes ({ states }: { states: Array<State> }) {
  if (states.length === 0) {
    return <div>--</div>;
  }

  return <>
    {
      states.map((state, index) => (
        <span key={state.code}>
          <Link to={`/system/states/${state._id}`}>{state.code}</Link>
          {states.length === index + 1 ? null : ', '}
        </span>
      ))
    }
  </>;
}
