import React from 'react';
import { useParams } from 'react-router-dom';
import SystemLayout from '../../Layouts/SystemLayout';
import ShowInvestorLayout from '../../Layouts/ShowInvestorLayout';
import { Table } from 'react-bootstrap';
import { InternalBankAccount, Lockbox, useInvestorInternalBankAccounts } from '../../../api/queries/investors/useInternalBankAccounts';
import { titleCase } from '../../../utils/string';
import EntityID from '../../../components/EntityID';

export default function InvestorsShowInternalBankAccounts () {
  const params = useParams<{ investorKey: string }>();
  const investorKey = params.investorKey as string;
  const {data: internalBankAccounts} = useInvestorInternalBankAccounts(investorKey);

  return (
    <SystemLayout selectedTab='investors' heading={titleCase(investorKey)}>
      <ShowInvestorLayout
        investorKey={ investorKey }
        selectedTab='bank-accounts'
      >
        { internalBankAccounts
          ? <InternalBankAccountsTable internalBankAccounts={internalBankAccounts}/>
          : <div>Loading…</div> }
      </ShowInvestorLayout>
    </SystemLayout>
  );
}

function InternalBankAccountsTable ({ internalBankAccounts }: { internalBankAccounts: Array<InternalBankAccount> }) {

  const formatLockbox = (lockbox: Lockbox) => {
    return <>
      {lockbox.name} <br/>
      {lockbox.address.addressLine1} {lockbox.address.addressLine2} <br/>
      {lockbox.address.city}, {lockbox.address.state} {lockbox.address.zip}
    </>
  }

  return <Table>
    <thead style={{ fontSize: '0.9rem' }}>
    <tr>
      <th>Label</th>
      <th>Last Four</th>
      <th>Standard Lockbox</th>
      <th>Expedited Lockbox</th>
      <th>Collection Portfolios</th>
    </tr>
    </thead>

    <tbody style={{ fontSize: '0.9rem' }}>
    {
      internalBankAccounts.map(internalBankAccount => (
        <tr key={internalBankAccount._id}>
          <td>{internalBankAccount.label}<br />
            <EntityID link={ false } id={ internalBankAccount._id } />
          </td>
          <td>{internalBankAccount.lastFour}</td>
          <td>{formatLockbox(internalBankAccount.mainLockbox)}</td>
          <td>{formatLockbox(internalBankAccount.expeditedLockbox)}</td>
          <td>{internalBankAccount.collectionPortfolios.join(', ')}</td>
        </tr>
      ))
    }
    </tbody>
  </Table>;
}
