import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { InlineError } from '../../components';
import ApplicationAndLoanSummaryCards from '../../components/ApplicationAndLoanSummaryCards';
import InlineLoadingIndicator from '../../components/InlineLoadingIndicator';
import InlineWarning from '../../components/InlineWarning';
import useLoanApplications from '../../api/queries/agents/useLoanApplications';
import useLoansSummaries from '../../api/queries/agents/useLoansSummaries';
import useAgent from '../../api/queries/agents/useAgent';

export default function AgentShowPage () {
  const { agentId } = useParams<{ agentId: string }>();
  const agentReq = useAgent(agentId);
  const loanApplicationsReq = useLoanApplications(agentId);
  const loansReq = useLoansSummaries(agentId);

  useEffect(() => {
    if (agentReq.data && agentReq.data.firstName) {
      document.title = `${agentReq.data.firstName} ${agentReq.data.lastName} | Notebook`
    }
  }, [agentReq.data]);

  if (agentReq.error) {
    if (agentReq.error?.request?.status === 404) {
      return <InlineWarning>No such Agent. Check the ID in the URL.</InlineWarning>;
    }
    return <InlineError>
      { agentReq.error.request ? `(${ agentReq.error.request.status })` : null } Error loading agent details.
    </InlineError>;
  }

  const agent = agentReq.data;
  if (!agent) {
    return <InlineLoadingIndicator>Loading…</InlineLoadingIndicator>;
  }

  return (
    <div className='pt-2'>
      <div className='d-flex'>
        <h4 className='m-0'>{`${agent.firstName} ${agent.lastName}`}</h4>
      </div>
      <hr/>
      <ApplicationAndLoanSummaryCards loanApplicationsReq={loanApplicationsReq} loansReq={loansReq} />
    </div>
  );
}
