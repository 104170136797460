import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation, useParams } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import './App.scss';
import AdminContext from './adminContext';

import EditIdentityFlow from './views/EditIdentityFlow';
import DisbursementsPage from './pages/DisbursementsPage';
import Search from './pages/Search/Search';
import Login from './pages/Login';
import Navbar from './components/Navbar';
import useSelf from './api/queries/admins/useSelf';
import ProductShowPage from './pages/System/Products/ProductShowPage';
import StateShowPage from './pages/System/States/StateShowPage';
import StatesPage from './pages/System/States/StatesPage';
import PartnersPage from './pages/System/Partners/PartnersPage';
import PartnerShowInfoPage from './pages/System/Partners/PartnerShowInfoPage';
import PartnerShowProductsPage from './pages/System/Partners/PartnerShowProductsPage';
import PartnerShowAgentsPage from './pages/System/Partners/PartnerShowAgentsPage';
import LoanApplicationShowPage from './pages/LoanApplication/LoanApplicationShowPage';
import LoanShowPage from './pages/LoanApplication/LoanShowPage';
import UnderwritingPage from './pages/LoanApplication/UnderwritingPage';
import LensPage from './pages/LoanApplication/LensPage';
import DocumentsPage from './pages/LoanApplication/DocumentsPage';
import LoanServicingShowPage from './pages/LoanApplication/LoanServicingShowPage';
import TimelinePage from './pages/LoanApplication/TimelinePage';
import UserShowPage from './pages/Users/UserShowPage';
import useLoan from './api/queries/loans/useLoan';
import InlineLoadingIndicator from './components/InlineLoadingIndicator';
import { InlineError } from './components';
import useLoanApplication from './api/queries/loan-applications/useLoanApplication';
import PartnerShowHistoryPage from './pages/System/Partners/PartnerShowHistoryPage';
import InvestorsPage from './pages/System/Investors/InvestorsPage';
import InvestorsShowInfoPage from './pages/System/Investors/InvestorsShowInfoPage';
import InvestorsShowPendingRemitsPage from './pages/System/Investors/InvestorsShowPendingRemitsPage';
import InvestorsShowReportsPage from './pages/System/Investors/InvestorsShowReportsPage';
import InvestorsShowReportDetailPage from './pages/System/Investors/InvestorsShowReportDetailPage';
import InvestorsShowInternalBankAccounts from './pages/System/Investors/InvestorsShowBankAccountsPage';
import InvestorsShowPortfoliosPage from './pages/System/Investors/InvestorsShowPortfoliosPage';
import InvestorsShowPortfolioPage from './pages/System/Investors/InvestorsShowPortfolioPage';
import SimulatorPage from './pages/System/Simulator/SimulatorPage';
import AgentShowPage from './pages/Agents/AgentShowPage';

const ALLOWED_LEGACY_TABS = ['loan', 'underwriting', 'lens', 'documents', 'servicing', 'timeline'];

function RedirectLegacyBorrowerOverview () {
  const { search, hash } = useLocation();
  const query = new URLSearchParams(search);

  return <Navigate to={`/users/${query.get('id')}${hash.slice(1)}`} replace/>;
}

function RedirectLegacyLoanAppView () {
  const { loanApplicationId, tab } = useParams<{ loanApplicationId: string, tab?: string }>();
  const loanApp = useLoanApplication(loanApplicationId);
  if (loanApp.error) { return <InlineError>Error loading loan application</InlineError>; }
  if (!loanApp.data) { return <InlineLoadingIndicator>Loading…</InlineLoadingIndicator>; }

  return <Navigate to={`/loan-applications/${loanApplicationId}${tab && ALLOWED_LEGACY_TABS.includes(tab) ? `/${tab}` : loanApp.data.loan ? '/loan' : ''}`} replace/>;
}

function RedirectLegacyLoanView () {
  const { loanId, tab } = useParams<{ loanId: string, tab?: string }>();
  const loan = useLoan(loanId);
  if (loan.error) { return <InlineError>Error loading loan</InlineError>; }
  if (!loan.data) { return <InlineLoadingIndicator>Loading…</InlineLoadingIndicator>; }

  const loanAppId = loan.data.loanApplication._id;
  return <Navigate to={`/loan-applications/${loanAppId}/${tab && ALLOWED_LEGACY_TABS.includes(tab) ? `${tab}` : 'loan'}`} replace/>;
}

function App () {
  const { error, data: admin } = useSelf();

  if (error) { return <Login />; }
  if (!admin) { return null; }

  return (
    <Router>
      <AdminContext.Provider value={{
        adminId: admin._id,
        adminRole: admin.role,
        canEditSystemSettings: admin.canEditSystemSettings,
      }}>
        <Container className='rootContainer'>
          <Navbar/>
          <Routes>
            {/* LoanApp Show Routes */}
            <Route path="/loan-applications/:loanApplicationId" element={<LoanApplicationShowPage/>}/>
            <Route path="/loan-applications/:loanApplicationId/loan" element={<LoanShowPage/>}/>
            <Route path="/loan-applications/:loanApplicationId/underwriting" element={<UnderwritingPage/>}/>
            <Route path="/loan-applications/:loanApplicationId/lens" element={<LensPage/>}/>
            <Route path="/loan-applications/:loanApplicationId/documents" element={<DocumentsPage/>}/>
            <Route path="/loan-applications/:loanApplicationId/servicing/*" element={<LoanServicingShowPage/>}/>
            <Route path="/loan-applications/:loanApplicationId/timeline" element={<TimelinePage/>}/>
            <Route path="/users/:userId" element={<UserShowPage/>}/>
            <Route path="/users/:userId/loan-application/:loanApplicationId/:tab?" element={<RedirectLegacyLoanAppView/>}/>
            <Route path="/users/:userId/loan/:loanId/:tab?" element={<RedirectLegacyLoanView/>}/>
            <Route path="/users/:userId/edit-identity" element={<EditIdentityFlow/>}/>
            <Route path='/agents/:agentId' element={<AgentShowPage/>}/>
            <Route path="/disbursements" element={<DisbursementsPage/>}/>
            <Route path="/system/partners" element={<PartnersPage/>}/>
            <Route path="/system/partners/:partnerId/info" element={<PartnerShowInfoPage/>}/>
            <Route path="/system/partners/:partnerId/products" element={<PartnerShowProductsPage/>}/>
            <Route path="/system/partners/:partnerId/agents" element={<PartnerShowAgentsPage/>}/>
            <Route path="/system/partners/:partnerId/history" element={<PartnerShowHistoryPage/>}/>
            <Route path="/system/products/:productId" element={<ProductShowPage/>}/>
            <Route path="/system/products/:productId/portfolios/:portfolioId" element={<ProductShowPage/>}/>
            <Route path="/system/states" element={<StatesPage/>}/>
            <Route path="/system/states/:stateId" element={<StateShowPage/>}/>
            <Route path="/system/investors" element={<InvestorsPage/>}/>
            <Route path="/system/investors/:investorKey/info" element={<InvestorsShowInfoPage/>}/>
            <Route path="/system/investors/:investorKey/pending-remits" element={<InvestorsShowPendingRemitsPage/>}/>
            <Route path="/system/investors/:investorKey/reports" element={<InvestorsShowReportsPage/>}/>
            <Route path="/system/investors/:investorKey/reports/:reportId" element={<InvestorsShowReportDetailPage/>}/>
            <Route path="/system/investors/:investorKey/bank-accounts" element={<InvestorsShowInternalBankAccounts/>}/>
            <Route path="/system/investors/:investorKey/portfolios" element={<InvestorsShowPortfoliosPage/>}/>
            <Route path="/system/investors/:investorKey/portfolios/:portfolioKey" element={<InvestorsShowPortfolioPage/>}/>
            <Route path="/system/simulator" element={<SimulatorPage/>}/>
            <Route path="/" element={<Search/>}/>

            <Route path="/admin/usersview/borrower_overview" element={<RedirectLegacyBorrowerOverview/>}/>

            <Route path="/admin/disbursements" element={<Navigate to="disbursements" replace />} />
            <Route path="/admin" element={<Navigate to="/" replace />} />
          </Routes>
        </Container>
      </AdminContext.Provider>
    </Router>
  );
}

export default App;
