import React from 'react';
import { ServicingActivity, ServicingActivityBaseType, ServicingActivityType } from '../../../../api/queries/loans/useActivity';
import { DrawerSize } from '../../../Drawer';
import { CardActivityDetail } from './CardActivityDetail';
import { CheckDetail } from './CheckDetail';
import { DisbursementDetail } from './DisbursementDetail';
import { ExternalActivityDetail } from './ExternalActivityDetail';
import { PaymentDetail } from './PaymentDetail';
import { RefundDetail } from './RefundDetail';
import StatementAdjustmentDetail from './StatementAdjustmentDetail';
import { FeeActivityDetail } from './FeeActivityDetail';
import AccountingActivityDetail from './AccountingActivityDetail';

export const DRAWER_TYPES: {
  [key in ServicingActivityBaseType]?: DrawerSize;
} = {
  [ServicingActivityBaseType.CARD_ACTIVITY]: 'lg',
  [ServicingActivityBaseType.CHECK_ACTIVITY]: 'sm',
  [ServicingActivityBaseType.EXTERNAL_ACTIVITY]: 'sm',
  [ServicingActivityBaseType.ACH_ACTIVITY]: 'sm',
  [ServicingActivityBaseType.PAYMENT]: 'sm',
  [ServicingActivityBaseType.STATEMENT_ADJUSTMENT]: 'sm',
  [ServicingActivityBaseType.FEE_ACTIVITY]: 'sm',
  [ServicingActivityBaseType.ACCOUNTING_ACTIVITY]: 'sm',
};

export function ActivityDrawerContent (props: { loanId: string; activity: ServicingActivity, onChange: () => void }) {

  if (props.activity.base_type === ServicingActivityBaseType.CARD_ACTIVITY) {
    return (
      <CardActivityDetail
        loanId    = { props.loanId }
        activity  = { props.activity }
        onChange  = { props.onChange }
      />
    );
  }

  if (props.activity.base_type === ServicingActivityBaseType.CHECK_ACTIVITY) {
    return (
      <CheckDetail
        loanId    = { props.loanId }
        activity  = { props.activity }
        onChange  = { props.onChange }
      />
    );
  }

  if (props.activity.base_type === ServicingActivityBaseType.EXTERNAL_ACTIVITY) {
    return (
      <ExternalActivityDetail
        loanId    = { props.loanId }
        activity  = { props.activity }
        onChange  = { props.onChange }
      />
    );
  }

  // Payments not yet queued will be picked up here
  if (props.activity.base_type === ServicingActivityBaseType.PAYMENT) {
    return (
      <PaymentDetail
        loanId    = { props.loanId }
        activity  = { props.activity }
        onChange  = { props.onChange }
      />
    )
  }

  if (props.activity.base_type === ServicingActivityBaseType.ACH_ACTIVITY) {
    switch (props.activity.type) {
      case ServicingActivityType.DISBURSEMENT:
        return (
          <DisbursementDetail
            loanId    = { props.loanId }
            activity  = { props.activity }
            onChange  = { props.onChange }
            onInvoiceVerified  = { () => {} }
          />
        );
      // Payments that have been queued will be picked up here
      case ServicingActivityType.REPAYMENT:
        return (
          <PaymentDetail
            loanId    = { props.loanId }
            activity  = { props.activity }
            onChange  = { props.onChange }
          />
        );
      case ServicingActivityType.REFUND:
        return (
          <RefundDetail
            loanId    = { props.loanId }
            activity  = { props.activity }
            onChange  = { props.onChange }
          />
        );
    }
  }

  if (props.activity.base_type === ServicingActivityBaseType.STATEMENT_ADJUSTMENT) {
    return (
      <StatementAdjustmentDetail
        loanId    = { props.loanId }
        activity  = { props.activity }
        onChange  = { props.onChange }
      />
    );
  }

  if (props.activity.base_type === ServicingActivityBaseType.FEE_ACTIVITY) {
    return (
      <FeeActivityDetail
        loanId    = { props.loanId }
        activity  = { props.activity }
        onChange  = { props.onChange }
      />
    );
  }

  if (props.activity.base_type === ServicingActivityBaseType.ACCOUNTING_ACTIVITY) {
    return (
      <AccountingActivityDetail
        loanId    = { props.loanId }
        activity  = { props.activity }
        onChange  = { props.onChange }
      />
    );
  }

  return (
    <div>
      Unsupported activity type { props.activity.base_type } { props.activity.base_id }
    </div>
  );
}
