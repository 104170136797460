import React from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

function TabContentLayout ({ heading, children }: { heading: string, children: React.ReactNode }) {
  return (
    <>
      <div className='mt-3'>
        <h3>{heading}</h3>
      </div>
      {children}
    </>
  );
}

type TabNames = Record<string, string | null>;

export default function TabLayout ({
  tabs,
  id,
  selectedTab,
  children,
  navigateTo,
  heading,
}: {
  id: string;
  tabs: TabNames,
  selectedTab: keyof TabNames,
  children: React.ReactNode,
  heading?: string,
  navigateTo: (selected: string) => string
}) {
  const navigate = useNavigate();

  return (
    <div>
      <Tabs
        className='mt-3'
        defaultActiveKey={selectedTab}
        id={ id }
        onSelect={(selected: string) => navigate(navigateTo(selected), { replace: true }) }
      >
        {
          Object.entries(tabs).map(([key, value]) => (
            value ?
              <Tab key={key} eventKey={key} title={value}>
                { key === selectedTab && <TabContentLayout heading={ key === selectedTab && heading ? heading : value}>
                  {children}
                </TabContentLayout> }
              </Tab>
            : null
          ))
        }
      </Tabs>
    </div>
  );
}
