import React from 'react';
import SystemLayout from '../../Layouts/SystemLayout';
import { usePartners } from '../../../api/queries/partners/usePartners';
import Simulator from './Simulator';

export default function PartnersPage () {
  const partnersRequest = usePartners();

  if (!partnersRequest.data) {
    return null;
  }

  return (
    <SystemLayout selectedTab='simulator'>
      <Simulator partners={partnersRequest.data.filter(p => p.active)}/>
    </SystemLayout>
  );
}
